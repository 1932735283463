footer.footer {
    border-top: 1px solid #eef0f1;
    text-align: center;
    width: 100%;
    padding-top: 30px;
    margin-top: 120px;
    font-family: SFUIText-Medium;
    font-size: 15px;
    color: #546b77;
}

footer.footer a {
    color: #546b77;
    text-decoration: none;
}