@import './assets/fonts/fonts.css';
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.container {
  display: flex;
  max-width: 1200px;
  width: 100%;
  flex-direction: column;
  padding: 0 20px 30px 20px;
  margin: 0 auto;
}

#kassa {
  position: fixed;
  bottom: 0;
  right: 0;
}

#kassa a {
  position: absolute;
}

.span-link {
  cursor: pointer;
  transition: color .3s;
}

.span-link:hover {
  color: #40a9ff;
}

.span-link-blue {
  color: #1890ff;
}

.video-wrap {
  position: relative;
  height: 0;
  overflow: hidden;
  width: 100%;
  padding-bottom: 56.25%;
}

.video-wrap iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}