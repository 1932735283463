@font-face {
    font-family: SFUIText-Medium;
    src: url(./SFUIText-Medium.otf);
}

@font-face {
    font-family: SFUIText-Regular;
    src: url(./SFUIText-Regular.otf);
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 600;
    src: url('./OpenSans-Bold.ttf');
}

@font-face {
    font-family: 'Open Sans';
    font-weight: 400;
    src: url('./OpenSans-Regular.ttf');
}