.regSuccess-links-link {
    font-size: 20px;
    display: block;
    text-align: center;
}

.regSuccess-links-link a {
    margin-left: 5px;
}

.regSuccess h1 {
    font-family: SFUIText-Medium;
    font-size: 23px;
    margin-bottom: 10px;
    color: #2f3b64 !important;
}