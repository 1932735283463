.vkboss-payment-cont {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    width: 100%;
    height: 30%;
    min-height: 300px
}

.vkboss-payment-cont .regSuccess-links {
    margin-top: 30px;
}